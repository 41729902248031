import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '356'
  },
  xl: {
    height: '400'
  }
}

const HomeEquityMortgage = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home-equity-mortgage.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h2'>
        Prêt d’aide à l’accession, qu’est-ce que c’est&nbsp;?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Outre les prêts immobiliers, il existe des prêts d’aide à l’accession, mais qu’est-ce que c’est exactement&nbsp;?
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Folhomee vous présente ci-dessous les deux types de prêts d’aide à l’accession les plus fréquents. Prêt à taux zéro et TVA réduite n’auront bientôt plus de secret pour vous&nbsp;!
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default HomeEquityMortgage
