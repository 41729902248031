import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PTZMortgageTable from '../../molecules/PTZMortgageTable'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
  padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
  padding: 0;
  `}
`

const PTZMortgage = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Le prêt à taux zéro, une aide financière intéressante
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le prêt à taux zéro, également appelé PTZ, est un prêt d’aide à l’accession. Comme son nom l’indique, il s’agit d’un prêt immobilier pour lequel le taux d’intérêt est de 0%. Bien évidemment, des conditions viennent encadrer ce type de prêt immobilier assez spécifique.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Pour pouvoir prétendre au prêt à taux zéro, il faut&nbsp;:
    </HomeSectionDescription>
    <StyledList>
      <li>
        respecter des conditions de ressources précises&nbsp;;
      </li>
      <li>
        acheter un bien immobilier neuf qui se trouve dans une zone éligible&nbsp;;
      </li>
      <li>
        être primo-accédant, donc propriétaire pour la première fois.
      </li>
    </StyledList>
    <HomeSectionDescription>
      Actuellement, il existe 4 zones pour le PTZ, retrouvez tous les détails <Link to='/pret-taux-zero'>ici</Link>. Attention : le prêt à taux zéro ne peut pas couvrir l’ensemble d’un achat immobilier.
    </HomeSectionDescription>
    <br />
    <HomeSectionDescription>
      Voici les pourcentages du coût total pris en charge par le PTZ&nbsp;:
    </HomeSectionDescription>
    <PTZMortgageTable />
  </Container>
)

export default PTZMortgage
