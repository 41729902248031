import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import TVAMortgageTable from '../../molecules/TVAMortgageTable'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TextContainer = styled.div`
  display: flex;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 60px;
`

const StyledButton = styled(LaunchButton)`
  margin-top: 15px;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin:16px auto 0;
`

const PTZMortgage = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      La TVA réduite, comment en profiter&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Acheter un bien immobilier est un acte soumis à 20% de TVA. Dans certaines situations, il est possible de profiter de la TVA réduite, soit d’un taux de 5,5%&nbsp;: globalement, cela permet souvent d’économiser plusieurs milliers d’euros.
    </HomeSectionSubtitle>
    <TextContainer>
      <TextContent>
        <HomeSectionDescription>
          Comme tous les prêts d’aide à l’accession à la propriété, il est de mise de remplir certaines conditions pour pouvoir accéder à la TVA réduite&nbsp;:
        </HomeSectionDescription>
        <StyledList>
          <li>
            le bien immobilier doit se trouver dans, ou dans un rayon de 300 mètres, une zone d’aménagement et de rénovation urbaine (ANRU), ou dans un quartier prioritaire de la politique de la ville (QPV)&nbsp;;
          </li>
          <li>
            l’achat doit être réalisé pour une résidence principale&nbsp;;
          </li>
          <li>
            l’acquéreur doit respecter un certain plafond de ressource&nbsp;;
          </li>
          <li>
            le prix au mètre carré ne doit pas dépasser un certain plafond.
          </li>
        </StyledList>
      </TextContent>
      <TableContainer>
        <TVAMortgageTable />
        <StyledHomeSectionDescription>
          Les plafonds de prix pour l’allègement de la TVA.
        </StyledHomeSectionDescription>
      </TableContainer>
    </TextContainer>
    <ButtonContainer>
      <HomeSectionDescription>
        <strong>Besoin d’aide pour vérifier votre éligibilité&nbsp;?</strong>
      </HomeSectionDescription>
      <StyledButton
        link='/services/neuf'
        title='Demandez à nos experts'
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default PTZMortgage
