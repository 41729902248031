import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '630'
  },
  xl: {
    height: '555'
  }
}

const InFineMortgage = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "infine-mortgage.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={true}
    imageData={placeholderImage}
    backgroundColor='paleGrey'>
    <HomeSectionTitle tag='h3'>
      Comprendre ce qu’est le prêt <em>in fine</em>
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le prêt <em>in fine</em> est un autre type de prêt immobilier, qui s’oppose en quelques sortes au prêt amortissable, puisqu’il s’agit d’un crédit non amortissable. Autrement dit, le capital n’est pas remboursé par des mensualités.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Il s’agit d’un crédit assez spécifique, qui ne s’adresse pas à tous les investisseurs. Le prêt <em>in fine</em> concerne surtout les gros investisseurs car il présente des vertus fiscales. Avec le prêt in fine, les investisseurs ne remboursent le capital qu’à la fin du crédit, soit 10, 15 ou 20 ans après la signature du prêt.
    </HomeSectionDescription>
    <HomeSectionSubtitle>
      Alors, comment fonctionne le prêt <em>in fine</em> ?
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Pendant toute la durée de l’emprunt, l’acquéreur paye les intérêts à sa banque et possède des produits d’épargne.
    </HomeSectionDescription>
    <br />
    <HomeSectionDescription>
      Cela permet de ne pas comptabiliser le bien en cours d’achat dans le calcul de l’impôt sur la Fortune Immobilière (IFI) et de déduire davantage d’intérêts d’emprunt des revenus fonciers.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default InFineMortgage
