import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '496'
  },
  xl: {
    height: '416'
  }
}

const InformationMortgage = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "information-mortgage.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Garantie bancaire, apport, taux d’endettement : informations pratiques sur les prêts immobiliers
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Contracter un emprunt bancaire n’est pas toujours chose aisée. Dans les faits, il n’est pas rare que les acquéreurs se posent des questions et se sentent un peu perdus.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Nous vous livrons les informations pratiques pour tous les types de prêts immobiliers. Garantie bancaire, taux d’endettement, apport… Zoom sur les différents types de prêts immobiliers d’un point de vue pratique.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default InformationMortgage
