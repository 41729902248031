import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1054px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  ${media.lessThan('md')`
    padding: 32px;
  `}
`

const StyledButton = styled(LaunchButton)`
  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const RepayableMortgage = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Le prêt amortissable, qu’est-ce que c’est ?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le prêt amortissable est le type de prêt immobilier le plus courant et donc le plus classique. Son fonctionnement est très simple : la banque prête une certaine somme à l’acquéreur, qui rembourse chaque mois une mensualité, ainsi que des intérêts d’emprunt.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Avec ce <strong>type de prêt immobilier</strong>, les mensualités et les intérêts sont calculés sur toute la durée de l’emprunt. Comme pour la grande majorité des prêts, les premières mensualités correspondent aux intérêts bancaires. Durant plusieurs mois, ou années selon le montant du prêt, l’acquéreur rembourse uniquement les intérêts bancaires.
    </HomeSectionDescription>
    <br />
    <HomeSectionDescription>
      Ce n’est qu’une fois les intérêts payés, que le remboursement du capital débute. Cela permet aux banques de s’assurer de percevoir leurs intérêts en cas de problème. En cas de souci, notamment si l’acquéreur ne peut plus rembourser ses mensualités, le capital sera remboursé à la banque par les assurances.
    </HomeSectionDescription>
    <ImageContainer>
      <StaticImage
        src='../../../images/repayable-mortgage.png'
        alt="Prêt amortissable, qu'est ce que c'est ?"
        style={{ maxWidth: 476 }}
        fadeIn={false} 
        loading='eager' />
    </ImageContainer>
    <HomeSectionSubtitle>
      En termes de durée, le prêt amortissable peut s’étendre sur 5, 10, 15, ou 20 ans, voire plus selon la situation.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      À vrai dire, il est souvent compliqué de <strong>trouver le type de prêt immobilier qui nous convient</strong>, ainsi que la banque qui propose la meilleure option. Folhomee vous propose un accompagnement de A à Z, de la recherche de votre bien à son ameublement, en passant par son financement.
    </HomeSectionDescription>
    <ButtonContainer>
      <StyledButton
        link='/services/emprunt-immobilier/'
        title='Profitez de nos partenariats et de taux avantageux'
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default RepayableMortgage
