import React from 'react'
import styled from 'styled-components'
import { map, isEqual, get } from 'lodash'

import media from '../../../utils/media'

const ZONES = [{
  title: 'Zone ABis et A',
  lines: ['40%']
}, {
  title: 'Zone B1',
  lines: ['40%']
}, {
  title: 'Zone B2',
  lines: ['20%']
}, {
  title: 'Zone C',
  lines: ['20%']
}]

const Table = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 40px;
`

const TitleSquare = styled.div`
  display: flex;
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  padding: 0 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
  line-height: 1.3em;
  width: 175px;
  box-shadow: 0px 0px 10px #213EAA40;

  ${media.lessThan('lg')`
    border-radius: 8px;
    font-size: 20px;
    max-width: 120px;
    margin-right: 8px;
  `}

  ${media.lessThan('md')`
    font-size: 12px;
    max-width: 100px;
    margin-right: 4px;
  `}

  ${media.lessThan('md')`
    border-radius: 5px;
    font-size: 10px;
    max-width: 75px;
    padding: 0px 10px;
    margin-right: 3px;
    margin-bottom: 0px;
  `}
`

const ZoneBubble = styled.div`
  position: relative;
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  background-color: ${({ theme }) => get(theme, 'skyBlue')};
  text-align: center;
  border-radius: 7px;
  padding: 12px 7px;
  width: 160px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 80%;
    left: 40%;
    border: 15px solid transparent;
    border-bottom-color: ${({ theme }) => get(theme, 'skyBlue', '#fff')};
    transform: rotate(180deg);
  }

  ${media.lessThan('lg')`
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 40%;
      border: 7px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'skyBlue', '#fff')};
      transform: rotate(180deg);
    }
    border-radius: 4px;
    font-size: 16px;
    width: 130px;
    padding: 10px;
  `}

  ${media.lessThan('md')`
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 80%;
      left: 35%;
      border: 7px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'skyBlue', '#fff')};
      transform: rotate(180deg);
    }
    border-radius: 4px;
    font-size: 11px;
    width: 100px;
    padding: 4px;
  `}

  ${media.lessThan('sm')`
    font-size: 10px;
    width: 75px;
  `}

`

const Column = styled.div`
  margin-right: 5px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #213EAA40;

  &:last-of-type {
    margin-right: 0;
  }

  ${media.lessThan('lg')`
    margin-right: 3px;
    border-radius: 8px;
  `}

  ${media.lessThan('md')`
    margin-right: 2px;
    border-radius: 5px;
  `}
`

const Cell = styled.div`
  font-weight: 600;
  font-size: 19px;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  background-color: ${({ idx, theme }) => get(theme, isEqual(idx % 2, 1) ? 'paleGrey' : 'white')};
  padding: 14px 6px;

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${media.lessThan('md')`
    font-size: 10px;
    padding: 7px;
    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `}
`

const ZonesColumns = () => map(ZONES, ({ title, lines }) => (
  <Column key={`column-${title}`}>
    <ZoneBubble>{title}</ZoneBubble>
    <div>
      {map(lines, (total, idx) => (
        <Cell idx={idx} key={`cell-${idx}-${total}`}>
          {total}
        </Cell>
      ))}
    </div>
  </Column>
))

const PTZMortgageTable = () => (
  <Table box={true}>
    <TitleSquare
      dangerouslySetInnerHTML={{ __html: 'Logement neuf' }} />
    <ZonesColumns />
  </Table>
)

export default PTZMortgageTable
