import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoPurse from '../../../assets/purse.inline.svg'
import PictoPromoter from '../../../assets/promoter.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoPurse,
  title: 'La sûreté personnelle',
  description: 'Un organisme se porte garant du remboursement de l’emprunt. Si l’acheteur ne parvient pas à payer ses mensualités, l’organisme se substituera à lui pour verser le capital restant dû à la banque'
}, {
  icon: PictoPromoter,
  title: 'La sûreté réelle',
  description: 'Bien souvent, cela correspond à l’hypothèque d’un bien immobilier. Si l’acheteur ne rembourse plus ses mensualités, le logement sera ainsi saisi et vendu aux enchères.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
  padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
  padding: 0;
  `}
`

const GarantieMortgage = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Apport et garantie bancaire pour les prêts immobiliers
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Aujourd’hui, 98% des banques demandent un apport personnel pour les prêts immobiliers : il s’agit d’une somme qui prouve à l’établissement bancaire que votre profil est sérieux.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Si vous n’avez pas la possibilité de fournir un apport, il est possible d’avoir recours à la garantie bancaire.<br />Il existe deux catégories de garanties bancaires :
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
    <HomeSectionDescription>
      Ces <strong>deux types de garanties bancaires</strong> sont accordées uniquement pour le montant du capital emprunté. Acheter un bien immobilier suppose en effet bien souvent des frais annexes, tels que les frais de notaire, par exemple (2 ou 3% pour les biens immobiliers neufs et 8% pour l’ancien). Il apparaît en fait que l’apport personnel sert à financer ces frais, en plus de constituer une preuve de sérieux pour la banque.
    </HomeSectionDescription>
  </Container>
)

export default GarantieMortgage
