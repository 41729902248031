import React from 'react'
import styled from 'styled-components'
import { map, isEqual, get } from 'lodash'

import media from '../../../utils/media'

const MAX_PRICES = ['4 886 €', '3 702 €', '2 965 €', '2 588 €', '2 263 €']

const ZONES = ['Zone A bis', 'Zone A', 'Zone B1', 'Zone B2', 'Zone C']

const ResourcesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Table = styled.div`
  display: flex;
  flex: 1;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  width: 100%;
  justify-content: center;
`

const StyledTable = styled(Table)`
  margin-top: 8px;
`

const FirstColumn = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`

const TitleSquare = styled.div`
  display: flex;
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  padding: 15px 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.3em;
  width: 175px;
  box-shadow: 0px 0px 10px #213EAA40;

  ${media.lessThan('lg')`
    margin-bottom: 5px;
    max-width: 135px;
    padding: 15px 10px;
  `}
`

const StyledTitleSquare = styled(TitleSquare)`
  background-color: ${({ theme }) => get(theme, 'skyBlue', '#fff')};
  padding: 29px 10px;

  ${media.lessThan('lg')`
    max-width: 135px;
    padding: 15px 10px;
  `}
`

const ZonesBubble = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  background-color: ${({ idx, theme }) => get(theme, isEqual(idx % 2, 0) ? 'skyBlue' : 'blue')};
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
  padding: 9px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 20%;
    left: 97%;
    border: 10px solid transparent;
    border-bottom-color: ${({ idx, theme }) => get(theme, isEqual(idx % 2, 0) ? 'skyBlue' : 'blue')};
    transform: rotate(90deg);
  }

  ${media.lessThan('lg')`
    margin-top: ${({ top }) => top / 2.9}px;
    padding: 3px;
  `}
`

const Column = styled.div`
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #213EAA40;
  flex: 1;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-right: 0;
  }
`

const Cell = styled.div`
  font-size: 19px;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  background-color: ${({ idx, theme }) => get(theme, isEqual(idx % 2, 1) ? 'paleGrey' : 'white')};
  padding: 14px 6px;
  width: 100%;

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${media.lessThan('lg')`
    padding: 5px;
  `}
`

const ZoneColumn = ({ zones }) => map(zones, (zone, idx) => (
  <ZonesBubble
    idx={idx}
    top={12}
    key={`zones-column-${zone}`}
    dangerouslySetInnerHTML={{ __html: zone }} />
))

const PricesColumn = ({ prices }) => map(prices, (price, idx) => (
  <Cell
    idx={idx}
    key={`cell-${idx}-${price}`}
    dangerouslySetInnerHTML={{ __html: price }} />
))

const TVAMortgageTable = () => (
  <ResourcesContainer>
    <Table box={true}>
      <FirstColumn>
        <TitleSquare
          dangerouslySetInnerHTML={{ __html: 'Zones géographiques' }} />
        <ZoneColumn
          zones={ZONES} />
      </FirstColumn>
      <div>
        <StyledTitleSquare
          dangerouslySetInnerHTML={{ __html: 'Prix maximum' }} />
        <StyledTable>
          <Column>
            <div>
              <PricesColumn prices={MAX_PRICES} />
            </div>
          </Column>
        </StyledTable>
      </div>
    </Table>
  </ResourcesContainer>
)

export default TVAMortgageTable
