import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const BuildingProgram = props => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "offer-mortgage.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <GatsbyImage
      alt='Programmes immobiliers neufs chez Folhomee'
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      {...props} />
  )
}

const StyledImage = styled(BuildingProgram)`
  position: absolute;
  width: 1155px;
  height: auto;
  top: 40%;
  right: 0;
  transform: translateY(-35%);
`

export default StyledImage
