import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 840px;
  margin: auto;
  height: 600px;

  ${media.greaterThan('xl')`
    max-width: 550px;
    margin-right: 50%;
  `}
`

const MortgageHero = () => (
  <>
    <StyledContainer>
      <Header max={100}>
        Les types de prêts immobiliers et d’aide à l’accession
      </Header>
      <Subtitle>
        Lors d’un achat immobilier, il est souvent de mise de réaliser un emprunt bancaire. En effet, il est assez rare d’effectuer un achat comptant. Il existe à ce jour différents types de prêts immobiliers : le prêt immobiliers bancaire et le prêt d’aide à l’accession.
      </Subtitle>
      <Description>
        Tout d’abord, il convient de différencier ces deux types de prêt immobiliers. Folhomee vous livre tout ce qu’il faut savoir sur les emprunts bancaires relatifs à l’achat d’un bien immobilier.
      </Description>
    </StyledContainer>
  </>
)

export default MortgageHero
