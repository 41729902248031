import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ProgramsIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "intro-mortgage.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={true}
      alt='programme immobilier neuf'
      imageData={placeholderImage}
      backgroundColor='white'
      maxText='500'>
        <HomeSectionTitle tag='h2'>
          Les différents types de prêts immobiliers en détails
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Pour acheter un bien immobilier, qu’il s’agisse d’un appartement ou d’une maison, vous aurez peut-être recours à un emprunt.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Les prêts les plus fréquemment accordés sont <strong>le prêt amortissable et le prêt relais</strong>. Retour en détails sur ces deux types de prêts immobiliers. Bien comprendre leur fonctionnement est essentiel avant de vous lancer dans votre projet d’achat immobilier.
        </HomeSectionDescription>
    </ImageSection>
  )
}

export default ProgramsIntro
