import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import PTZMortgage from '../components/organisms/PTZMortgage'
import TVAMortgage from '../components/organisms/TVAMortgage'
import SiteSection from '../components/atoms/SiteSection/'
import MortgageIntro from '../components/organisms/MortgageIntro'
import RelayMortgage from '../components/organisms/RelayMortgage'
import InFineMortgage from '../components/organisms/InFineMortgage'
import GarantieMortgage from '../components/organisms/GarantieMortgage'
import DebtRateMortgage from '../components/organisms/DebtRateMortgage'
import RepayableMortgage from '../components/organisms/RepayableMortgage'
import HomeEquityMortgage from '../components/organisms/HomeEquityMortgage'
import MortgageTopSection from '../components/organisms/MortgageTopSection'
import InformationMortgage from '../components/organisms/InformationMortgage'

const TITLE = 'Quels sont les différents types de prêt immobilier ?'
const DESCRIPTION = 'Folhomee vous livre tout sur les prêts immo, l\'apport, les garanties bancaires, ...'

const MortgageType = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      location={location}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <MortgageTopSection />
    <SiteSection id='types-prets-intro' $first={true}>
      <MortgageIntro />
    </SiteSection>
    <SiteSection id='prêt-amortissable'>
      <RepayableMortgage />
    </SiteSection>
    <SiteSection id='prêt-in-fine'>
      <InFineMortgage />
    </SiteSection>
    <SiteSection id='prêt-relais'>
      <RelayMortgage />
    </SiteSection>
    <SiteSection id='prêt-aide-accession'>
      <HomeEquityMortgage />
    </SiteSection>
    <SiteSection id='prêt-taux-zero'>
      <PTZMortgage />
    </SiteSection>
    <SiteSection id='prêt-tva-réduite'>
      <TVAMortgage />
    </SiteSection>
    <SiteSection id='prêt-informations-pratiques'>
      <InformationMortgage />
    </SiteSection>
    <SiteSection id='prêt-taux-endettement'>
      <DebtRateMortgage />
    </SiteSection>
    <SiteSection id='prêt-taux-garantie'>
      <GarantieMortgage />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

MortgageType.propTypes = {
  location: PropTypes.object.isRequired
}

export default MortgageType
