import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
  padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
  padding: 0;
  `}
`

const RelayMortgage = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Le prêt relais, un type de prêt immobilier qui peut être très pratique
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le prêt relais est un type de prêt immobilier qui intervient dans la situation suivante : vous avez un bien immobilier que vous devez vendre pour acheter un nouveau logement.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Le fonctionnement de cet emprunt est assez simple. Le banquier avance une partie du prix de vente de votre bien immobilier sous la forme d’un prêt relais, donc un prêt dont vous ne payez que les intérêts.
    </HomeSectionDescription>
    <br />
    <HomeSectionDescription>
      Une fois que vous avez vendu votre bien actuel, il faudra solder le capital du prêt relais. À noter que le taux d’intérêt de ce type de prêt immobilier est bien souvent plus élevé qu’un emprunt bancaire classique.
    </HomeSectionDescription>
  </Container>
)

export default RelayMortgage
