import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
  padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
  padding: 0;
  `}
`

const FormulaContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  padding: 14px 21px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => get(theme, 'lightOrange')};
  margin: 32px auto 0;
  text-align: center;

  ${media.greaterThan('sm')`
    margin: 40px auto 0;
  `}
`

const DebtRateMortgage = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Prêt immobilier : calculer son taux d’endettement
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le taux d’endettement se détermine par le poids des charges mensuelles de l’emprunt bancaire par rapport aux revenus de l’acheteur. Il est calculé dans le but de s’assurer que l’acquéreur pourra honorer les mensualités de son prêt immobilier.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Durant de longues années, le taux d’endettement était fixé à 33%. Depuis la crise liée au Covid-19, dans le but de limiter la tendance des banques à freiner l’accès aux crédits, le taux d’endettement a été revu à la hausse. Désormais, les personnes peuvent profiter d’un taux d’endettement de 35%.
    </HomeSectionDescription>
    <FormulaContainer>
      <HomeSectionDescription>
        Pour calculer son taux d’endettement, la méthode est relativement simple :
      </HomeSectionDescription>
      <HomeSectionDescription>
        <strong>Salaire mensuel net avant impôts x 0,35 = Mensualité maximale.</strong>
      </HomeSectionDescription>
    </FormulaContainer>
  </Container>
)

export default DebtRateMortgage
