import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import OfferImage from '../../molecules/OfferImage'
import MortgageHero from '../../molecules/MortgageHero'

const StyledOfferImage = styled(OfferImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 600px;
    top: 210px;
  `}
`

const MortgageTopSection = () => (
  <TopSection component={StyledOfferImage}>
    <MortgageHero />
  </TopSection>
)

export default MortgageTopSection
